// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".td_psapH{background-color:var(--color-yellow-100);line-height:1.5rem;padding:.25rem 1rem;vertical-align:center}.content_UTH0s{align-items:center;display:flex;height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"td": "td_psapH",
	"content": "content_UTH0s"
};
module.exports = ___CSS_LOADER_EXPORT___;

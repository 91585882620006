
export default {
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true
    },

    colspan: {
      type: Number,
      required: true
    }
  }
};
